import React, { useEffect, useState } from 'react';
import Prismic from 'prismic-javascript';
import PrismicDOM from 'prismic-dom';

import Layout from '../components/layout';
import SEO from '../components/seo';

import 'lightbox-react/style.css';

import './../racing.css'
import logoRacing from './../images/BWRT_logo.png';

const prismicURL = 'https://blackway.prismic.io/api/v2';
console.log(logoRacing)

const Customs = () => {
	const [ title, setTitle ] = useState(null);
	const [ content, setContent ] = useState(null);

	useEffect(() => {
		Prismic.getApi(prismicURL).then((api) => api.query(
			Prismic.Predicates.at('document.type', 'black_way_racing_team')
		))
			.then(response => {
				console.log(response.results[0].data.title[0].text);
				setTitle(response.results[0].data.title[0].text);
				setContent(PrismicDOM.RichText.asHtml(response.results[0].data.content) || null);
			});
	}, []);

	console.log('title', title);
	console.log('content', content);

	return title !== null && content !== null ? (
		<Layout>
			<SEO
				title="Racing Team"
				description="Black Way Racing Team"
				lang="fr"
				url="/black-way-racing-team"
			/>

      <div style={{display: 'flex', justifyContent: 'center', margin: '120px'}}>
				 <img src={logoRacing} alt="Logo Black Way Racing Team - Geneva" />
      </div>

			<div className="box-container first">
				<div className="content-block">
					<div className="content-block-bg" />
					<h2>{title}</h2>
					<div dangerouslySetInnerHTML={{ __html: content }} />
				</div>
			</div>
		</Layout>
	) : null;
}

export default Customs;
